import React, { useState } from "react";
import "./index.css";
import BeforeAfter from "../before-after/BeforeAfter";
import Footer from "../footer/Footer";
import Faq from "../our-specialist/faq/Faq";
import { useSelector } from "react-redux";
import {
  LeftAnimatedDiv,
  RightAnimatedDiv,
  ZoomInDiv,
} from "../../componet/Animation";
import ShoppingFeature from "../shopping-feature/ShoppingFeature";
import Navbar from "../nav/Navbar";
import SouthIcon from "@mui/icons-material/South";
import MailIcon from "@mui/icons-material/Mail";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Helmet } from "react-helmet";

export default function Dermatologist() {
  const [read1, setRead1] = useState(false);
  const [read2, setRead2] = useState(false);
  const [read3, setRead3] = useState(false);
  const [read4, setRead4] = useState(false);
  const [read5, setRead5] = useState(false);
  const [read6, setRead6] = useState(false);
  const [read7, setRead7] = useState(false);
  const [read8, setRead8] = useState(false);
  const [read9, setRead9] = useState(false);
  const [read10, setRead10] = useState(false);

  const content = useSelector((state) => state.content.expertise);
  console.log("jojkrgreor", content);

  const navigate = useNavigate();

  let section4 = [
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423548/hair-assessment/gctpdtkibo3nen5zcnhm.png",
      text: "Identify the Root Cause Is your hair loss or damage due to genetics, stress, diet, or an underlying medical condition? Our online test helps pinpoint the exact cause of your hair concerns, giving you a clear path to address them.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423480/hair-assessment/iyg4qyjpehesjctlcv6z.png",
      //   desc: "Hair Procedures",
      text: "Get Personalized Solutions with Expert Support After identifying the cause of your hair issues, our dermatologists and hair counselors work together to provide customized solutions. Whether it's recommending the best hair growth treatments or offering lifestyle adjustments, our experts ensure the recommendations are perfectly suited to your needs.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423573/hair-assessment/z5bhoesxvlh89xjdclj5.png",
      //   desc: "Hair Transplants",
      text: "Save Time and Money No more trial and error. Our online test, combined with insights from our dermatologists and hair counselors, gives you accurate and expert-backed suggestions, saving you from spending on ineffective products.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423500/hair-assessment/lfqafjizvupmqwdhg83w.png",
      //   desc: "Natural Remedies",
      text: "Comprehensive Hair Analysis from a Dermatologist As part of the process, you’ll receive a detailed hair analysis from one of our experienced dermatologists, who will review your test results and images to provide a professional diagnosis. This ensures your treatment plan is based on medical expertise.",
    },
    {
      img: "https://res.cloudinary.com/drkpwvnun/image/upload/v1729423530/hair-assessment/zl161vfmpve9bnjc3eqs.png",
      //   desc: "Lifestyle Adjustments",
      text: "Take Control of Your Hair Health with Ongoing Support Beyond the test, our hair counselors are available to guide you through your hair care journey. They’ll answer your questions, help you understand your treatment options, and provide ongoing support as you work toward healthier, stronger hair",
    },
  ];

  const [selectedQ, setSelectedQ] = useState(0);

  let section8 = [
    {
      desc: "How does the Hairscares Online Hair Test work? ",
      text: "The Hairscares Online Hair Test is a quick and easy process where you answer questions about your hair type, concerns, and lifestyle. You can also upload images for a more detailed analysis. Our dermatologists and hair counselors then review your responses and images to provide personalized treatment recommendations.",
    },
    {
      desc: "Will I get a professional diagnosis through the online test?",
      text: "Yes! After completing the test, your results and images will be reviewed by one of our experienced dermatologists, who will provide a thorough diagnosis and recommend a personalized treatment plan based on your unique hair needs.",
    },
    {
      desc: "How long does it take to receive my personalized hair analysis? ",
      text: "Once you complete the Hairscares Online Hair Test and submit your images, you’ll typically receive a detailed analysis and personalized recommendations from our dermatologists within 24 to 48 hours.",
    },
    {
      desc: "What happens after I receive my test results? ",
      text: "After receiving your personalized hair analysis, you can consult with our hair counselors to discuss the recommended treatment plan. You’ll have the option to purchase the treatments directly, and all the necessary products will be delivered right to your doorstep.",
    },
    {
      desc: "Is there a cost for the Hairscares Online Hair Test? ",
      text: "Yes, there is a nominal charge for the online hair test and consultation. This fee covers your personalized hair analysis by a dermatologist and access to expert recommendations tailored to your specific hair concerns. Any treatments you choose to purchase will then be delivered conveniently to your home.",
    },
  ];

  let section5 = [
    {
      desc: "Convenience",
      text: "No need for clinic visits—get an expert hair diagnosis online.",
    },
    {
      desc: "Personalized Treatments",
      text: "Every treatment plan is crafted based on your unique hair and scalp needs.",
    },
    {
      desc: "Expert Evaluation",
      text: "Your case is reviewed by seasoned dermatologists and trichologists.",
    },
    {
      desc: "Ongoing Support",
      text: "Beyond diagnosis, we offer continued guidance to ensure the long-term health of your hair.",
    },
  ];

  return (
    <Navbar>
      <div
        className="d-flex flex-column "
        style={{ fontFamily: '"Poppins", sans-serif' }}
      >
        <Helmet>
          <link rel="canonical" href="https://www.hairsncares.com/dr-amit-agarkar-hair-restoration-expert" />
        </Helmet>
        <div style={{ background: "rgba(193, 237, 255, 1)", width: "100vw" }} className="main-div-resp">
          <div className="d-flex flex-column container">
            <div className="d-flex main-section-1-op">
              <ZoomInDiv
                className="d-flex flex-column main-section-1-op-left"
                style={{ padding: 0 }}
              >
                <div
                  className="text-2-section-4-htw"
                  style={{ textAlign: "left", fontWeight: "800" }}
                >
                  <h1>Dr. Amit S. Agarkar</h1>
                </div>
                <div
                  className="text-2-section-4-htw"
                  style={{ textAlign: "left" }}
                >
                  <h2>A Leader in{" "}
                    <span className="blue-btw-text">Hair Restoration</span>,
                    Trichology and Dermatology{" "}</h2>
                </div>
                <div className="text-3-section-1-htw-left">
                  <strong style={{ color: "rgba(0, 160, 227, 1)" }}>
                    (MBBS, MD (Dermatology), FCPS, DDV)
                  </strong>
                </div>
                <div className="text-3-section-1-htw-left">
                  <strong style={{ color: "rgba(79, 78, 78, 1)" }}>
                    (MBBS, MD (Dermatology), FCPS, DDV)
                  </strong>
                </div>
                <div className="d-flex text-3-section-1-htw-left">
                  <MailIcon style={{ color: "black" }} />
                  dragarkaramit@gmail.com
                </div>
                <div className="d-flex text-3-section-1-htw-left">
                  <MailIcon style={{ color: "black" }} />
                  info@vplanthairclinics.com
                </div>
                <HashLink
                  smooth
                  to="/contact-hair-experts/#section3"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {" "}
                  <button
                    // onClick={() => navigate("/our-expertise#read3")}
                    className="btn sub-button-section-3-op"
                  >
                    Meet Dr. Agarkar
                  </button>
                </HashLink>
              </ZoomInDiv>
              <RightAnimatedDiv className="d-flex flex-column main-section-1-op-right">
                <img src="/assets/img/hairTreatmentWomen/image.png"
                  alt="Dr. Amit Agarkar, leading expert in hair restoration, trichology, and dermatology, known for advanced hair loss treatments at HairsnCares."
                  title="Dr. Amit Agarkar – Hair Restoration Expert"
                />
              </RightAnimatedDiv>
            </div>
          </div>
        </div>

        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div
            className="d-flex flex-column container"
            style={{ gap: "15px", padding: "0 0 30px 0 " }}
          >
            <div className="desktop-view" style={{ gap: "30px" }}>
              <LeftAnimatedDiv
                className="main-section-2-htw-left"
              //   style={{ height: "100vh" }}
              >
                <img
                  src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729424606/hair-assessment/kh2b9nblfx8ven7gtqbl.png"
                  style={{ height: "100%", width: "100%" }}
                  alt="Dr. Amit Agarkar, renowned specialist in hair restoration and advanced hair loss treatments, seated in his office at HairsnCares."
                  title="Dr. Amit Agarkar – Professional Overview"
                />
              </LeftAnimatedDiv>
              <ZoomInDiv className=" main-section-2-htw-right d-flex flex-column">
                <div
                  className="text-2-section-2-htw"
                  style={{ textAlign: "left" }}
                >
                  <h2>Professional <span className="blue-btw-text">Overview</span></h2>
                </div>
                <div className="text-3-section-2-htw">
                  Hi there! I'm Dr. Amit Agarkar, and I’ve had the pleasure of
                  working in dermatology and hair restoration for over 20 years.
                  My passion? Helping people regain their confidence through
                  healthier, fuller hair. Now, I know how frustrating hair loss
                  can be. I’ve seen it all—people trying countless treatments,
                  shampoos, and gimmicks that promise results but fall short.
                  That’s why my goal is to provide real, effective solutions
                  backed by science and years of experience.
                  <br />
                  <br />I specialize in hair transplantation, and you may have
                  heard about some of the techniques I’ve pioneered, like MHI
                  (Modified Hair Implantation) and MHI+. These are advanced
                  methods that I’ve refined over the years to give you
                  natural-looking results with minimal downtime. Think of it as
                  a haircut with long-lasting benefits—except you’re not losing
                  hair, you’re gaining it! The best part? These techniques work
                  wonders even for those with severe thinning or baldness.
                </div>
              </ZoomInDiv>
            </div>

            <ZoomInDiv className="text-3-section-2-htw">
              My clinics, Vplant Advanced Hair Clinics, are located in Mumbai,
              Kochi, and Kozhikode. Whether you’re looking for the best
              dermatologist near you or simply need answers about your hair
              loss, we’ve got you covered.
              <br />
              <br />
              Now, let me take you through some of the exciting treatments I
              offer and why they’ve been game-changers for so many patient
            </ZoomInDiv>
          </div>
        </div>

        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div
            className="d-flex flex-column container"
            style={{ gap: "20px", padding: "30px 0 30px 0" }}
          >
            <div className="desktop-view-1" style={{ gap: "2%" }}>
              <LeftAnimatedDiv className="left-div-11">
                <img
                  src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729425727/hair-assessment/msn64qtand9swjcuhlyf.png"
                  style={{ width: "100%", height: "400px" }}
                  alt="Reception area at Vplant Advanced Hair Clinics, welcoming patients for specialized hair restoration and advanced hair loss treatments."
                  title="Vplant Advanced Hair Clinics Reception"
                />
              </LeftAnimatedDiv>
              <RightAnimatedDiv className="right-div-11">
                <img
                  src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729425800/hair-assessment/rb9t2bukqoshatgnoaf4.png"
                  style={{ width: "100%", height: "400px" }}
                  alt="Wall displaying awards and certifications at Vplant Advanced Hair Clinics, showcasing excellence in hair restoration and dermatology under Dr. Amit Agarkar."
                  title="Awards and Certifications at Vplant Hair Clinics"
                />
              </RightAnimatedDiv>
            </div>
            <ZoomInDiv>
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729425837/hair-assessment/strdghe87pztti3ypsc7.png"
                style={{ width: "100%", height: "400px" }}
                alt="Patient waiting area at Vplant Advanced Hair Clinics, providing a comfortable environment for those seeking advanced hair loss treatments and care."
                title="Patient Waiting Area at Vplant Hair Clinics"
              />
            </ZoomInDiv>
          </div>
        </div>

        <div className="main-div-resp">
          <div
            className="d-flex flex-column container"
            style={{ padding: "30px 0 30px 0" }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ZoomInDiv
                className="text-2-section-4-htw"
                style={{ width: "100%" }}
              >
                <h3>
                  Innovation at{" "}
                </h3>
                <span className="blue-btw-text">HairsnCares.com</span>
              </ZoomInDiv>
            </div>
            <div
              className="d-flex main-section-1-op"
              style={{ paddingBottom: 0 }}
            >
              <ZoomInDiv
                className="d-flex flex-column main-section-1-op-left"
                style={{ padding: 0 }}
              >
                <div
                  className="text-2-section-4-htw"
                  style={{ textAlign: "left" }}
                >
                  Bringing <span className="blue-btw-text">Hair Solutions</span>{" "}
                  Online{" "}
                </div>
                <div className="text-3-section-1-htw-left">
                  One of the most exciting parts of my journey in hair
                  restoration has been the development of HairsnCares.com, a
                  platform that’s truly revolutionizing how we approach hair
                  loss diagnostics. HairsnCares is an innovative online tool
                  designed to give you quick, reliable insights into the causes
                  of your hair loss—all from the comfort of your home.
                  <br />
                  <br />
                  At HairsnCares, we’ve simplified the process: you start by
                  completing a detailed hair loss test online, which covers
                  everything from your diet to stress levels and even lets you
                  upload photos of your scalp. Based on your responses, our team
                  of expert dermatologists reviews your data and provides a
                  personalized diagnosis and treatment plan. Whether you’re
                  dealing with hair thinning or more advanced baldness, the
                  recommendations are tailored to your specific needs.
                </div>
                <div className="">
                  <button
                    onClick={() => navigate("/take-hair-test")}
                    className="btn sub-button-section-3-op"
                  >
                    Take A Hair Test
                  </button>
                </div>
              </ZoomInDiv>
              <RightAnimatedDiv className="d-flex flex-column main-section-1-op-right">
                <img src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729426832/hair-assessment/fhxvbiii1tlmaupt0tk2.png"

                  alt="Young woman using online hair diagnosis tool on HairsnCares.com, accessing personalized hair loss solutions and advanced hair care guidance."
                  title="Online Hair Diagnosis"
                />
              </RightAnimatedDiv>
            </div>
          </div>
        </div>

        <div className="main-div-resp">
          <div
            className="d-flex flex-column container"
            style={{ padding: "2% 0 4% 0 " }}
          >
            <ZoomInDiv style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-2-section-5-htw">
                Our{" "}
                <span className="blue-btw-text">
                  Unique Selling Propositions
                </span>{" "}
                (USPs)
              </div>
            </ZoomInDiv>
            <ZoomInDiv
              style={{ display: "flex", justifyContent: "center" }}
              className="mt-5"
            >
              <div className="text-3-section-5-htw" style={{ width: "75%" }}>
                Whether you're trying to save time or looking for a more
                budget-friendly approach to hair care, HairsnCares is your
                one-stop-shop for professional hair advice. We’ve made it
                possible for patients to take control of their hair health
                without ever stepping foot in a clinic.
              </div>
            </ZoomInDiv>
            <div className="desktop-view-1 mt-3" style={{ gap: "15px" }}>
              {section5?.map((item, indx) => {
                return (
                  <ZoomInDiv
                    className="mini-card-section-4-htw width-for-text-heading-23 d-flex flex-column"
                    style={{
                      padding: "30px",
                      // width: "23%",
                      alignItems: "center",
                      gap: "15px",
                      border: "none",
                      background:
                        indx == 1 ? "rgba(0, 160, 227, 1)" : "#FFFFFF",
                    }}
                  >
                    <div
                      className="mini-card-num-div-section-4-htw"
                      style={{ padding: "10px", width: "20%" }}
                    >
                      <div className="mini-card-num-text-section-4-htw flex-center-row">
                        {indx + 1}
                      </div>
                    </div>
                    <div
                      className="mini-card-text-1-section-4-htw"
                      style={{
                        color: indx == 1 ? "#FFFFFF" : "black",
                      }}
                    >
                      {item?.desc}
                    </div>
                    <div
                      className="mini-card-text-2-section-4-htw"
                      style={{
                        color: indx == 1 ? "#FFFFFF" : "rgba(97, 97, 97, 1)",
                      }}
                    >
                      {item?.text}
                    </div>
                  </ZoomInDiv>
                );
              })}
            </div>
          </div>
        </div>

        <div className="d-flex flex-column main-section-3-d main-div-resp">
          <div
            className="d-flex flex-column container mw-90"
            style={{ padding: "30px 0 30px 0 " }}
          >
            <ZoomInDiv className="text-1-section-3-htw"><h3>HairUnlock</h3></ZoomInDiv>
            <ZoomInDiv style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-2-section-3-htw" style={{ width: "100%" }}>
                A Hub for <span className="blue-btw-text">Hair Research</span>{" "}
                and Information
              </div>
            </ZoomInDiv>
            <ZoomInDiv
              style={{ display: "flex", justifyContent: "center" }}
              className="mt-3"
            >
              <div className="text-3-section-3-htw m-text-left" style={{ width: "70%" }}>
                Alongside HairsnCares, we’ve also launched HairUnlock, a
                comprehensive resource website dedicated to all things hair.
                Whether you're interested in learning about the latest research
                on hair loss, understanding the best haircare routines, or
                exploring the science behind different treatments, HairUnlock is
                here to help. The idea behind HairUnlock is simple: we believe
                that empowering people with knowledge is the first step toward
                better hair health.
              </div>
            </ZoomInDiv>
            <RightAnimatedDiv
              style={{ display: "flex", justifyContent: "center" }}
            >
              <button
                onClick={() => navigate("/take-hair-test")}
                className="btn sub-button-section-3-op"
              >
                Start Your Hair Assessment{" "}
              </button>
            </RightAnimatedDiv>

            <LeftAnimatedDiv
              className="desktop-view-1"
              style={{ gap: "30px", padding: "3% 0 3% 0" }}
            >
              <div className="main-section-2-htw-right d-flex flex-column">
                <div
                  className="text-2-section-2-htw"
                  style={{ textAlign: "left", color: "white" }}
                >
                  Here’s what makes{" "}
                  <span className="blue-btw-text">HairUnlock</span> stand out
                </div>

                <div
                  className="d-flex flex-column  mt-5"
                  style={{ gap: "20px" }}
                >
                  <ZoomInDiv className="d-flex" style={{ gap: "20px" }}>
                    <div
                      className="mini-card-num-div-section-4-htw number-view"
                    // style={{ padding: "10px", width: "8%", height: "5vh" }}
                    >
                      <div className="mini-card-num-text-section-4-htw flex-center-row">
                        1
                      </div>
                    </div>
                    <div
                      className="text-3-section-2-htw"
                      style={{ color: "white", width: "80%" }}
                    >
                      <strong>Up-to-Date Information</strong>
                      <br />
                      We cover the latest studies and advancements in hair loss
                      treatments and research
                    </div>
                  </ZoomInDiv>

                  <ZoomInDiv className="d-flex" style={{ gap: "20px" }}>
                    <div
                      className="mini-card-num-div-section-4-htw number-view"
                    // style={{ padding: "10px", width: "8%", height: "5vh" }}
                    >
                      <div className="mini-card-num-text-section-4-htw flex-center-row">
                        2
                      </div>
                    </div>
                    <div
                      className="text-3-section-2-htw"
                      style={{ color: "white", width: "80%" }}
                    >
                      <strong>Comprehensive Guides</strong>
                      <br />
                      From haircare tips to explanations of different hair loss
                      treatments, HairUnlock is the go-to resource for
                      everything related to hair.
                    </div>
                  </ZoomInDiv>

                  <ZoomInDiv className="d-flex" style={{ gap: "20px" }}>
                    <div
                      className="mini-card-num-div-section-4-htw number-view"
                    // style={{ padding: "10px", width: "8%", height: "5vh" }}
                    >
                      <div className="mini-card-num-text-section-4-htw flex-center-row">
                        3
                      </div>
                    </div>
                    <div
                      className="text-3-section-2-htw"
                      style={{ color: "white", width: "80%" }}
                    >
                      <strong>Expert Contributions</strong>
                      <br />
                      Every piece of content is written or reviewed by experts
                      in dermatology and trichology to ensure accuracy.
                    </div>
                  </ZoomInDiv>

                  <ZoomInDiv className="d-flex" style={{ gap: "20px" }}>
                    <div
                      className="mini-card-num-div-section-4-htw number-view"
                    // style={{ padding: "10px", width: "8%", height: "5vh" }}
                    >
                      <div className="mini-card-num-text-section-4-htw flex-center-row">
                        4
                      </div>
                    </div>
                    <div
                      className="text-3-section-2-htw"
                      style={{ color: "white", width: "80%" }}
                    >
                      <strong>Interactive Content</strong>
                      <br />
                      Whether you prefer articles, videos, or quizzes, we’ve
                      made sure to include a variety of formats to keep you
                      engaged.
                    </div>
                  </ZoomInDiv>
                </div>
              </div>
              <div
                className="main-section-2-htw-left mp-2 "
              // style={{ height: "750px" }}
              >
                <img
                  src="https://res.cloudinary.com/drkpwvnun/image/upload/v1730232308/hair-assessment/iykhxiigandfakokp6xg.png"
                  style={{ height: "100%", width: "100%" }}
                  alt="Smiling woman with healthy, well-combed hair, illustrating the unique benefits of HairUnlock solutions for optimal hair health and care."
                  title="HairUnlock – Encyclopedia"
                />
              </div>
            </LeftAnimatedDiv>

            <RightAnimatedDiv
              className="text-3-section-2-htw mt-5"
              style={{ color: "white" }}
            >
              By examining various factors—including family history, hormonal
              imbalances, nutritional deficiencies, scalp conditions, stress
              levels, and lifestyle habits—this test provides valuable insights.
              Simply complete the questionnaire, and you’ll receive guidance on
              the factors affecting your hair health, along with recommendations
              for next steps.
            </RightAnimatedDiv>
          </div>
        </div>

        <div
          className="d-flex flex-column main-section-2-htw container mp-2 mw-90"
          style={{ padding: "4% 0 4% 0" }}
        >
          <div className="desktop-view" style={{ gap: "30px" }}>
            <LeftAnimatedDiv
              className="main-section-2-htw-left"
            //   style={{ height: "100vh" }}
            >
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729432519/hair-assessment/xlxbh9ivmrs3xv079lzd.png"
                style={{ height: "100%", width: "100%" }}
                alt="Preparing scalp for MHI & MHI+ hair transplant techniques, highlighting precise hairline design for advanced hair restoration."
                title="Hairline Design for MHI & MHI+"
              />
            </LeftAnimatedDiv>
            <div className=" main-section-2-htw-right d-flex flex-column">
              <ZoomInDiv
                className="text-2-section-2-htw"
                style={{ textAlign: "left" }}
              >
                <h2>MHI & MHI+ Techniques What’s{" "}</h2>
                <span className="blue-btw-text">All the Buzz</span> About?
              </ZoomInDiv>

              <div className="text-3-section-2-htw">
                In my early days as a hair transplant surgeon, I noticed a huge
                gap in the industry. People were getting results, sure—but they
                weren’t always natural or consistent. That’s when I developed
                MHI and MHI+, which are all about precision and enhanced growth.
                The MHI technique focuses on carefully implanting each hair
                follicle using cutting-edge tech, while MHI+ takes it a step
                further by incorporating VThread and PRP (Platelet-Rich Plasma)
                to supercharge growth. The goal? Thicker, healthier hair that
                lasts.
                <br />
                <br />I had a patient named Ramesh, for instance. He had tried
                almost every treatment under the sun—shampoos, medications, even
                laser caps. Nothing worked. When he came to me, he was skeptical
                (I mean, wouldn’t you be?), but after going through the MHI+
                treatment, the difference was night and day. Six months later,
                he had a fuller head of hair and—more importantly—his confidence
                was through the roof.
              </div>
            </div>
          </div>

          <div className="text-3-section-2-htw">
            The moral of the story? Personalized haircare is key. Every patient
            is different, and so are their hair concerns. Whether you’re dealing
            with balding, thinning, or just need a boost, there’s a treatment
            plan out there that’s perfect for you.
          </div>

          <ZoomInDiv
            className=""
            style={{ fontSize: "36px", fontWeight: "700" }}
          >
            <h3>Advanced <span className="blue-btw-text">Treatments</span> for{" "}
              <span className="blue-btw-text">Hair Loss</span> and Hair Thinning</h3>
          </ZoomInDiv>

          <div className="text-3-section-2-htw">
            One of the things I’m most excited about is how far hair restoration
            has come. Back in the day, options were pretty limited. Now? We’ve
            got a whole arsenal of treatments that are not only effective but
            also minimally invasive.
            <br />
            Take GFC PRP for example. This treatment uses growth factors from
            your own blood to stimulate hair follicles and promote regrowth.
            It's like giving your scalp a boost with its own natural healing
            properties. I also use treatments like VGrow and QR678 to target
            more stubborn cases of hair loss.
            <br />
            And it’s not just about hair transplants and treatments. We also do
            scalp micropigmentation, which is a non-surgical way to create the
            illusion of fuller hair. Think of it as a clever way to boost your
            look without any downtime.
            <br />
            If you’re wondering which treatment is right for you, don’t
            worry—we’ll figure it out together. My team and I always take the
            time to evaluate each patient’s needs and provide customized
            treatment plans.
          </div>
        </div>

        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div
            className="d-flex flex-column container mw-90"
            style={{ padding: "30px 0 30px 0" }}
          >
            <div
              className="d-flex main-section-1-op"
              style={{ paddingBottom: 0 }}
            >
              <div
                className="d-flex flex-column main-section-1-op-left"
                style={{ padding: 0 }}
              >
                <LeftAnimatedDiv
                  className="text-2-section-4-htw"
                  style={{ textAlign: "left" }}
                >
                  <h2>Academic <span className="blue-btw-text">Background</span> and{" "}
                    <span className="blue-btw-text">Professional</span> Journey</h2>
                </LeftAnimatedDiv>
                <div className="text-3-section-1-htw-left">
                  I didn’t just wake up one day and decide to get into
                  dermatology. My journey began at the Government Medical
                  College, Nagpur, where I earned my MBBS, followed by an MD in
                  Dermatology from Grant Medical College & J.J. Hospital,
                  Mumbai. I’ve also earned degrees like the FCPS and DDV, which
                  have equipped me with the skills needed to tackle everything
                  from cosmetic dermatology to clinical research.
                  <br />
                  <br />
                  My professional journey has been incredibly fulfilling. I’ve
                  worked as a Senior Hair Transplant Surgeon at DHI Hair
                  Transplant Center and as a Consultant Dermatologist at various
                  renowned hospitals. Currently, I’m proud to serve as the
                  Medical Director of Vplant Advanced Hair Clinics, where I lead
                  an amazing team of specialists.
                </div>
              </div>
              <RightAnimatedDiv className="d-flex flex-column main-section-1-op-right">
                <img src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729432889/hair-assessment/nzleighfp9j5mlc4iqjy.png"
                  alt="Comprehensive hair restoration and dermatology services offered at Vplant Advanced Hair Clinics, including MHI, FUE, laser treatments."
                  title="Academic Background" />
              </RightAnimatedDiv>
            </div>

            <div
              className="d-flex main-section-1-op"
              style={{ paddingBottom: 0 }}
            >
              <LeftAnimatedDiv
                className="d-flex flex-column main-section-1-op-right"
              // style={{ height: "90vh" }}
              >
                <img
                  src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729444754/hair-assessment/tfjwycwbn9uur2rnsw7m.png"
                  style={{ height: "100%", width: "100%" }}
                  alt="Researcher reviewing hair restoration products, representing ongoing research and publications in hair loss treatments and dermatology advancements"
                  title="Research and Publications"
                />
              </LeftAnimatedDiv>
              <div
                className="d-flex flex-column main-section-1-op-left"
                style={{ padding: 0 }}
              >
                <RightAnimatedDiv
                  className="text-2-section-4-htw"
                  style={{ textAlign: "left", fontSize: "50px" }}
                >
                  <h2>Research & Publications</h2>
                </RightAnimatedDiv>
                <div className="text-3-section-1-htw-left">
                  I’m also deeply involved in dermatology research, having
                  presented papers at numerous national and international
                  conferences. One of my more memorable presentations was on
                  "Carcinoma en cuirass secondary to breast carcinoma" at
                  Cuticon. I’m always looking to stay at the forefront of new
                  treatments and innovations because, let’s face it—medicine is
                  always evolving!
                </div>
                <h3><strong>Professional Affiliations:</strong></h3>
                <div>IADVL Member</div>
                <div>Association of Hair Restoration Surgeons (AHRS)</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex flex-column container mw-90"
          style={{ alignItems: "center", padding: "4% 0 4% 0" }}
        >
          <ZoomInDiv
            className="text-2-section-4-htw"
            style={{ textAlign: "left", fontSize: "48px" }}
          >
            <h2>Awards and Honors</h2>
          </ZoomInDiv>
          <ZoomInDiv
            className="text-3-section-1-htw-left"
            style={{ width: "70%", textAlign: "center" }}
          >
            Throughout my career, I've been fortunate to receive several
            prestigious awards that reflect my commitment to excellence in
            dermatology and hair restoration:
          </ZoomInDiv>
          <ZoomInDiv className="">
            <button
              onClick={() => navigate("/our-expertise#read3")}
              className="btn sub-button-section-3-op"
            >
              <h5 className="mfs-1">Global Healthcare Excellence Awards (2018){" "}</h5>
            </button>
          </ZoomInDiv>
          <ZoomInDiv
            className="text-3-section-1-htw-left"
            style={{ width: "50%", textAlign: "center" }}
          >
            Awarded "Best Hair Restoration Service Provider" for innovative
            techniques in hair transplantation.
          </ZoomInDiv>

          <ZoomInDiv style={{ position: "relative" }}>
            <div className="d-flex flex-column" style={{ gap: "150px" }}>
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex flex-column" style={{ width: "40%" }}>
                  <div className="">
                    <button
                      onClick={() => navigate("/our-expertise#read3")}
                      className="btn-d sub-button-section-3-op"
                    >
                      <h4 style={{ fontSize: "20x" }}>Global Healthcare Excellence Awards (2018){" "}</h4>
                    </button>
                  </div>
                  <div
                    className="text-3-section-1-htw-left"
                    style={{ width: "80%", textAlign: "center" }}
                  >
                    Recognized for leadership in advancing dermatology and
                    trichology practices
                  </div>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ width: "40%", alignItems: "end" }}
                >
                  <div className="">
                    <button
                      onClick={() => navigate("/our-expertise#read3")}
                      className="btn-d sub-button-section-3-op"
                    >
                      Pragati International Healthcare Awards (2019)
                    </button>
                  </div>
                  <div
                    className="text-3-section-1-htw-left"
                    style={{ width: "80%", textAlign: "center" }}
                  >
                    Honored for outstanding contributions to hair restoration
                    and patient care.
                  </div>
                </div>
              </div>

              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <div className="d-flex flex-column" style={{ width: "40%" }}>
                  <div className="">
                    <button
                      onClick={() => navigate("/our-expertise#read3")}
                      className="btn-d sub-button-section-3-op"
                    >
                      <h4 style={{ fontSize: "20x" }}>Global Healthcare Excellence Awards (2018){" "}</h4>
                    </button>
                  </div>
                  <div
                    className="text-3-section-1-htw-left"
                    style={{ width: "80%", textAlign: "center" }}
                  >
                    Recognized for leadership in advancing dermatology and
                    trichology practices
                  </div>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ width: "40%", alignItems: "end" }}
                >
                  <div className="">
                    <button
                      onClick={() => navigate("/our-expertise#read3")}
                      className="btn-d sub-button-section-3-op"
                    >
                      <h4 style={{ fontSize: "20x" }}>Pragati International Healthcare Awards (2019)</h4>
                    </button>
                  </div>
                  <div
                    className="text-3-section-1-htw-left"
                    style={{ width: "80%", textAlign: "center" }}
                  >
                    Honored for outstanding contributions to hair restoration
                    and patient care.
                  </div>
                </div>
              </div>

              <div className="cup-icon">
                <img
                  src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729446172/hair-assessment/jxqtmhrlmpnhbhis8utq.png"
                  style={{ height: "100%" }}
                  alt="Awards and honors received by Dr. Amit Agarkar, a leading expert in hair restoration and advanced hair loss treatments, recognizing excellence."
                  title="Awards and Honors "
                />
              </div>
            </div>
          </ZoomInDiv>

          <ZoomInDiv className="">
            <button
              onClick={() => navigate("/our-expertise#read3")}
              className="btn sub-button-section-3-op"
            >
              <h4 className="mfs-1">Global Healthcare Excellence Awards (2024)</h4>
            </button>
          </ZoomInDiv>

          <ZoomInDiv
            className="text-3-section-1-htw-left"
            style={{ width: "50%", textAlign: "center" }}
          >
            Recognized as "Best Dermatologist" for my work in both <h3>clinical and
              cosmetic dermatology</h3>
          </ZoomInDiv>

          <ZoomInDiv className="">
            <button
              onClick={() => navigate("/our-expertise#read3")}
              className="btn sub-button-section-3-op"
            >
              More Awards
            </button>
          </ZoomInDiv>

          <ZoomInDiv style={{ width: "100%" }}>
            <div className="d-flex" style={{ justifyContent: "space-around" }}>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn-d sub-button-section-3-op"
                >
                  <h4 style={{ fontSize: "20x" }}>Global Healthcare Excellence Awards (2024)</h4>
                </button>
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn-d sub-button-section-3-op"
                >
                  <h4 style={{ fontSize: "20x" }}>India Brand Icon (2024)</h4>
                </button>
              </div>
              <div className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn-d sub-button-section-3-op"
                >
                  <h4 style={{ fontSize: "20x" }}>India Brand Icon (2024)</h4>
                </button>
              </div>
            </div>
          </ZoomInDiv>
        </div>

        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div className="main-section-1-htw desktop-view-1 container">
            <div
              className="d-flex flex-column main-section-1-htw-left"
              style={{ padding: 0 }}
            // ref={ref_61}
            // animate={control_61}
            // initial="hidden"
            // variants={LEFT_VARIANTS}
            // transition={TRANSITION}
            >
              <ZoomInDiv className="text-1-section-1-htw-left">
                <h2>Final Thoughts</h2>
              </ZoomInDiv>
              <div className="text-3-section-1-htw-left">
                I’ve seen countless patients walk through my door feeling unsure
                and frustrated by their hair loss. And I get it—it’s tough. But
                I also know that with the right care and treatments, you can
                regain that confidence. Whether you're looking for the best hair
                transplant techniques or just some advice on how to improve your
                daily haircare routine, my team and I are here for you.
                <br />
                <br />
                Take it from me—after 20 years in this field, I’ve seen
                firsthand what works and what doesn’t. So if you’re feeling lost
                in the maze of hair treatments, don’t worry. You’ve got options,
                and we’re here to guide you every step of the way. Looking for
                the best dermatologist near you? You’ve found him.
                <br />
                <br />
                Ready to take control of your hair health? Let’s get started
                today at Vplant Advanced Hair Clinics. Your journey to healthier
                hair starts with one click!
              </div>
            </div>
            <RightAnimatedDiv
              className="d-flex flex-column main-section-1-htw-right"
            // ref={ref_62}
            // animate={control_62}
            // initial="hidden"
            // variants={RIGHT_VARIANTS}
            // transition={TRANSITION}
            >
              <img src="/assets/img/hairTreatmentWomen/image-17.png"
                alt="Final insights from Dr. Amit Agarkar on hair restoration and advanced hair loss treatments, highlighting comprehensive patient care and innovation."
                title="Final Insights"
              />
            </RightAnimatedDiv>
          </div>
        </div>

        <ShoppingFeature col={"1"} />
        <Footer />
      </div>
    </Navbar>
  );
}
