import React, { useState } from "react";
import "./index.css";
import BeforeAfter from "../before-after/BeforeAfter";
import Footer from "../footer/Footer";
import Faq from "../our-specialist/faq/Faq";
import { useSelector } from "react-redux";
import {
  LeftAnimatedDiv,
  RightAnimatedDiv,
  ZoomInDiv, ZoomInDiv2
} from "../../componet/Animation";
import ShoppingFeature from "../shopping-feature/ShoppingFeature";
import Navbar from "../nav/Navbar";
import { Padding } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function OtherProcedure() {
  const [read1, setRead1] = useState(false);
  const [read2, setRead2] = useState(false);
  const [read3, setRead3] = useState(false);
  const [read4, setRead4] = useState(false);
  const [read5, setRead5] = useState(false);
  const [read6, setRead6] = useState(false);
  const [read7, setRead7] = useState(false);
  const [read8, setRead8] = useState(false);
  const [read9, setRead9] = useState(false);
  const [read10, setRead10] = useState(false);

  const content = useSelector((state) => state.content.expertise);
  console.log("jojkrgreor", content);

  const navigate = useNavigate();


  return (
    <Navbar>
      <div
        className="d-flex flex-column"
        style={{ fontFamily: '"Poppins", sans-serif' }}
      >
        <Helmet>
          <link rel="canonical" href="https://www.hairsncares.com/advanced-hair-loss-solutions-prp-smp-cloning-systems" />
        </Helmet>
        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div className="d-flex flex-column container">
            <ZoomInDiv2 className="text-1-section-4-htw m-text-left"><h2>PRP Hair Treatment</h2></ZoomInDiv2>
            <ZoomInDiv2 style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-2-section-4-htw width-for-text-heading-80 m-text-left">
                <h1>A Natural <span className="blue-btw-text">Solution to Hair Loss</span> from Dr. Amit Agarkar</h1>
              </div>
            </ZoomInDiv2>
            <div className="desktop-view-1 main-section-1-op">
              <ZoomInDiv2 className="d-flex flex-column main-section-1-op-left style={{padding : 0}}">
                <div className="text-3-section-1-htw-left">
                  As someone who has dedicated over two decades to helping
                  people combat hair loss, I understand how frustrating and
                  emotionally taxing it can be to watch your hair thin or see
                  bald patches forming. Many of my patients come to me after
                  trying every possible remedy—special shampoos,
                  over-the-counter products, and even drastic haircuts to
                  conceal the problem, only to end up disappointed. This is
                  where PRP (Platelet-Rich Plasma) hair treatment comes in as a
                  game-changer.
                  <br />
                  <br />
                  With PRP, we tap into your body’s natural healing abilities to
                  promote hair regrowth. By using a concentrated form of
                  platelets from your own blood, PRP stimulates hair follicles
                  and encourages natural hair growth,
                  {!read1 ? (
                    <div
                      onClick={() => {
                        setRead1(!read1);
                      }}
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    >
                      Read More
                    </div>
                  ) : (
                    <>
                      {" "}
                      offering a non-surgical, safe, and effective solution. The
                      beauty of PRP is its versatility—whether you’re dealing
                      with early-stage thinning or more advanced hair loss, it’s
                      a personalized treatment option that can deliver
                      impressive results.
                      <br />
                      <br />
                      In this article, I’ll walk you through what makes PRP hair
                      treatment so effective, break down the costs, share real
                      patient success stories, and help you decide if this
                      innovative approach is right for you. Let’s explore how
                      PRP can help restore not just your hair, but your
                      confidence too.
                    </>
                  )}
                </div>
              </ZoomInDiv2>
              <RightAnimatedDiv className="d-flex flex-column main-section-1-op-right">
                <img src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729370270/hair-assessment/eqysqquo54pga1bjj81c.png"
                  alt="Man with restored hair reflecting confidence and success, showcasing advanced hair loss solutions like PRP."
                  title="PRP for Advanced Hair Loss Solutions"


                />
              </RightAnimatedDiv>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column main-section-2-htw container main-div-resp">
          <ZoomInDiv2 className="text-1-section-2-htw"><h2>Scalp Micropigmentation</h2></ZoomInDiv2>
          <ZoomInDiv2 className="text-2-section-2-htw">
            A Modern <span className="blue-btw-text">Solution for Hair</span> Loss
          </ZoomInDiv2>
          <div className="desktop-view" style={{ gap: "20px" }}>
            <LeftAnimatedDiv className="main-section-2-htw-left">
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1732862185/hair-assessment/rrh0mq5m3fprqkhgmhmy.png"
                style={{ height: "100%", width: "100%" }}
                alt="Close-up of scalp micropigmentation procedure, a non-surgical hair loss solution creating the appearance of hair follicles for a fuller look."
                title="Scalp micropigmentation"

              />
            </LeftAnimatedDiv>
            <RightAnimatedDiv className="text-3-section-2-htw main-section-2-htw-right">
              Many of my patients feel overwhelmed and frustrated when
              traditional treatments like shampoos, medications, or even hair
              transplants don’t deliver the results they hoped for. That’s where
              Scalp Micropigmentation (SMP) steps in as a fantastic alternative.
              Whether you’re dealing with thinning hair, bald spots, or a
              receding hairline, SMP offers a non-surgical, low-maintenance
              solution that can instantly transform your appearance and boost
              your confidence.
              <br />                  <br />

              SMP works by carefully placing tiny micro-dots of pigment into the
              scalp to replicate the look of real hair follicles. The result is
              the appearance of a closely shaved head or denser hair coverage,
              making it a versatile choice
              {!read2 ? (
                <div
                  onClick={() => {
                    setRead2(!read2);
                  }}
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  Read More
                </div>
              ) : (
                <>
                  {" "}
                  for both men and women. One of the best things about SMP is
                  its immediate results—no waiting around for months to see a
                  difference! Plus, with minimal discomfort and no downtime,
                  it’s an option that fits seamlessly into busy lives.
                  <br />                  <br />

                  In this guide, I’ll walk you through everything you need to
                  know about scalp micropigmentation—from how it works and
                  costs, to finding the right provider and real success stories.
                  Let’s explore if SMP is the right solution for you!{" "}
                </>
              )}
            </RightAnimatedDiv>
          </div>
        </div>

        <div className="main-section-3-op main-div-resp">
          <div className="d-flex flex-column container">
            <ZoomInDiv2 className="text-1-section-3-htw"><h2>Hair Cloning in 2024</h2></ZoomInDiv2>
            <ZoomInDiv2 style={{ display: "flex", justifyContent: "center" }}>
              <div className="text-2-section-3-htw" style={{ width: "100%" }}>
                The Future of <span className="blue-btw-text">Hair Restoration</span> or Just a Buzz?
              </div>
            </ZoomInDiv2>
            <div className="main-section-1-op d-flex">
              <LeftAnimatedDiv
                className="d-flex flex-column main-section-1-op-left"
                style={{ padding: 0 }}
              >
                <div
                  className="text-3-section-1-htw-left"
                  style={{ color: "#FFFFFF" }}
                >
                  As someone who’s been at the forefront of hair restoration for
                  over two decades, I’m always keeping an eye on the latest
                  breakthroughs. One of the most exciting—and
                  controversial—developments is hair cloning. Imagine having the
                  ability to create an unlimited supply of hair follicles from
                  just a few healthy ones. It sounds like a dream solution,
                  right? Before jumping in, let’s take a moment to separate the
                  reality from the hype.
                  <br />
                  <br />
                  In theory, hair cloning could revolutionize how we approach
                  hair loss. By multiplying healthy follicles in a lab and
                  reimplanting them, this technique has the potential to provide
                  permanent solutions for
                  {!read3 ? <div onClick={() => { setRead3(!read3) }} style={{ fontWeight: "700", fontSize: "20px", cursor: "pointer" }}>Read More</div> : <>

                    even severe cases of hair thinning and
                    baldness. But here’s the catch: it’s still very much in the
                    experimental phase. While researchers are making progress,
                    we’re not quite there yet. Challenges like ensuring consistent
                    hair growth, follicle survival, and long-term results are
                    still being ironed out.
                    <br />
                    <br />
                    So, is hair cloning worth waiting for, or should you consider
                    other proven methods like hair transplants or scalp
                    micropigmentation? In this article, I’ll break down what you
                    need to know about hair cloning in 2024, the science behind
                    it, and whether it’s truly the game-changer we’ve been hoping
                    for. </>}
                </div>
              </LeftAnimatedDiv>
              <RightAnimatedDiv className="d-flex flex-column main-section-1-op-right">
                <img src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729371958/hair-assessment/rkslsvd3hn7ejxhm0szf.png"
                  alt="Research scientist in laboratory, studying hair cloning technology advancements for hair restoration solutions in 2024."
                  title="Hair Cloning for Hair Restoration"


                />
              </RightAnimatedDiv>
            </div>
          </div>
        </div>


        <div className="d-flex flex-column main-section-2-htw container main-div-resp">
          <div className="text-1-section-2-htw"><h2>Hair Systems</h2></div>
          <ZoomInDiv className="text-2-section-2-htw">
            A Realistic and <span className="blue-btw-text">Instant Solution</span> for Hair Loss
          </ZoomInDiv>
          <div className="desktop-view">
            <LeftAnimatedDiv className="main-section-2-htw-left">
              <img
                src="https://res.cloudinary.com/drkpwvnun/image/upload/v1729372244/hair-assessment/pqhtrcaue6d9vtwqabbh.png"
                style={{ height: "100%", width: "100%" }}
                alt="Man holding a hair system piece while touching his scalp, exploring instant solutions for hair loss with realistic hair systems."
                title="Hair Systems for Hair Loss"
              />
            </LeftAnimatedDiv>
            <RightAnimatedDiv className="text-3-section-2-htw main-section-2-htw-right">
              As a dermatologist and hair restoration specialist, I’ve seen countless patients struggle with the emotional toll of hair loss. Many of them come to me looking for a natural, non-invasive solution that doesn’t involve surgery or long recovery times. For those who want immediate results, a hair system can be a game-changer.
              <br />                  <br />

              Hair systems, also known as non-surgical hair replacement, offer a realistic and comfortable way to regain the appearance of a full head of hair. The beauty
              {!read4 ? (
                <div
                  onClick={() => {
                    setRead4(!read4);
                  }}
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                >
                  Read More
                </div>
              ) : (
                <>
                  {" "}
                  of these systems is that they’re completely customizable—designed to blend seamlessly with your existing hair, matching your color, texture, and density. From those dealing with mild thinning to complete baldness, hair systems provide an instant transformation that can restore your confidence.
                  <br />                  <br />

                  In this article, I’ll break down what makes hair systems such an effective and popular choice for men and women alike.
                  You’ll learn about the different types available, how to choose the best hair system, and what to expect in terms of cost and longevity. Let’s explore how this non-surgical solution could be the answer you’ve been looking for to reclaim your natural look.
                </>
              )}
            </RightAnimatedDiv>
          </div>
        </div>



        <div style={{ background: "rgba(193, 237, 255, 1)" }} className="main-div-resp">
          <div className="d-flex flex-column container">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ZoomInDiv2 className="text-2-section-4-htw" style={{ width: "80%" }}>
                Click to Read
              </ZoomInDiv2>
            </div>
            <div className="desktop-view-2 main-section-1-op" style={{ padding: 0 }}>
              <LeftAnimatedDiv className="">
                <button
                  onClick={() => navigate("/our-expertise#read3")}
                  className="btn sub-button-section-3-op"
                  style={{ height: "100%" }}
                >
                  <h2>VGROW Treatment</h2>{" "}
                </button>
              </LeftAnimatedDiv>
              <LeftAnimatedDiv className="">
                <button
                  onClick={() => navigate("/our-expertise#read5")}
                  className="btn sub-button-section-3-op"
                  style={{ height: "100%" }}
                >
                  <h2>GFC PRP</h2>

                </button>
              </LeftAnimatedDiv>
              <LeftAnimatedDiv className="">
                <button
                  onClick={() => navigate("/our-expertise#read4")}
                  className="btn sub-button-section-3-op"
                  style={{ height: "100%" }}
                >
                  <h2>Biotin PRP</h2>
                  {" "}
                </button>
              </LeftAnimatedDiv>
              <RightAnimatedDiv className="">
                <button
                  onClick={() => navigate("/our-expertise#read6")}
                  className="btn sub-button-section-3-op"
                  style={{ height: "100%" }}
                >
                  <h2>Stem Cell Therapy (Meso Therapy)</h2>

                </button>
              </RightAnimatedDiv>
              <RightAnimatedDiv className="">
                <button
                  onClick={() => navigate("/our-expertise#read7")}
                  className="btn sub-button-section-3-op"
                  style={{ height: "100%" }}
                >
                  <h2>QR678</h2>
                </button>
              </RightAnimatedDiv>
            </div>
            <ZoomInDiv2 className="flex-center-row">
              <button
                onClick={() => navigate("/our-expertise#read8")}
                className="btn sub-button-section-3-op"
                style={{ height: "100%" }}
              >
                <h2>Laser Hair Growth Treatment</h2>

              </button>
            </ZoomInDiv2>
          </div>
        </div>


        <ShoppingFeature col={"1"} />
        <Footer />
      </div>
    </Navbar>
  );
}
